import { AdvancedType, BasicType } from "easy-email-core";
import { ExtensionProps } from "easy-email-extensions";
import { CustomBlocksType } from "./constants";
import { BlockAvatarWrapper } from "easy-email-editor";
import { CampaignTypeT } from "../services/campaign";
import { LISTSOCIALICONS, socialListToElementObject } from "./SocialBlock/components/constants";


const payloadMenu = {
  data: {
    value: {
      links: [
        {
          href: '#',
          label: 'MENU'
        },
        {
          href: '#',
          label: 'CARRINHO'
        },
        {
          href: '#',
          label: 'HOME'
        },
      ]
    }
  }
};

export const defaultCategories: ExtensionProps['categories'] = [
	{
	  label: 'Conteúdo',
	  active: true,
	  blocks: [
		{
		  title: "Texto",
		  type: AdvancedType.TEXT,
      payload: {
        data: {
          value: {
            content: 'Escreva aqui...',
          },
        }
      }
		},
		{
		  payload: {
        attributes: {
          padding: "0px 0px 0px 0px",
          'container-background-color': '#FFFFFF',
          width: '600px',
          height: 'auto'
        },
		  },
		  title: "Imagem",
		  type: AdvancedType.IMAGE,
		},
		{
		  title: "Botão",
		  type: AdvancedType.BUTTON,
		},
		{
		  title: "Rede Social",
		  type: AdvancedType.SOCIAL,
      payload: {
        data: {
          value: {
            elements: socialListToElementObject(LISTSOCIALICONS[0])
          },
        }
      }
		},
		{
		  title: "Divisor",
		  type: AdvancedType.DIVIDER,
		},
		{
		  title: "Espaço",
		  type: AdvancedType.SPACER,
		},
		{
		  title: "Wrapper",
		  type: AdvancedType.WRAPPER,
		}
	  ],
	},
	{
	  label: 'Opções especial',
	  displayType: 'custom',
	  active: true,
	  blocks: []
	},
	{
	  label: 'Linhas',
	  active: true,
	  displayType: 'column',
	  blocks: [
		{
		  title: '2 colunas',
		  payload: [
			['50%', '50%'],
			['33%', '67%'],
			['67%', '33%'],
			['25%', '75%'],
			['75%', '25%'],
		  ],
		},
		{
		  title: '3 colunas',
		  payload: [
			['33.33%', '33.33%', '33.33%'],
			['25%', '25%', '50%'],
			['50%', '25%', '25%'],
		  ],
		},
		{
		  title: '4 colunas',
		  payload: [['25%', '25%', '25%', '25%']],
		},
	  ],
	},
];

export const categoriesCustom = (type: CampaignTypeT): ExtensionProps['categories'] => {
  type validSpecialBlocksType = keyof typeof specialBlocks;
  const validSpecialBlocks = Object.keys(specialBlocks) as Omit<validSpecialBlocksType, 'others'>[]
  const typeBlocks = validSpecialBlocks.includes(type) ? type as validSpecialBlocksType : 'others';
  return [
    defaultCategories[0],
    {
      label: 'Opções especial',
      displayType: 'custom',
      active: true,
      blocks: specialBlocks[typeBlocks]
      
    },
    defaultCategories[2]
  ]
};

export const fontList = [
	'Roboto',
	'Arial',
	'Tahoma',
	'Verdana',
	'Times New Roman',
	'Courier New',
	'Georgia',
	'Lato',
	'Montserrat',
].map(item => ({ value: item, label: item }));


const specialBlocks = {
  'checkout': [
    <div className='custom-block two-columns'>
      <BlockAvatarWrapper type={BasicType.BUTTON} payload={{
        attributes: {
        href: '%LINK_CHECKOUT%', 
        },
        data: {
        value: {
          content: 'FINALIZAR COMPRA'
        }
        }
      }}>
        <div className='container-box full'>
          <img src='images/link-solid.svg' alt='carrinho imagem' />
          <span>Link Carrinho</span>
        </div>
      </BlockAvatarWrapper>
      <BlockAvatarWrapper type={CustomBlocksType.PRODUCT_CHECKOUT}>
        <div className='container-box'>
        <img src='images/cart-shopping-solid.svg' alt='produtos checkout imagem' />
        <span>Produtos Checkout</span>
        </div>
      </BlockAvatarWrapper>
      <BlockAvatarWrapper type={CustomBlocksType.PRODUCT_RECOMMENDATION}>
        <div className='container-box'>
        <img src='images/box-open-solid.svg' alt='produtos recomendados imagem' />
        <span>Produtos Recomendados</span>
        </div>
      </BlockAvatarWrapper>
      <BlockAvatarWrapper type={CustomBlocksType.MENU_NAV} payload={payloadMenu}>
        <div className='container-box'>
        <img src='https://api.iconify.design/eva/menu-fill.svg' alt='produtos recomendados imagem' />
        <span>Menu</span>
        </div>
      </BlockAvatarWrapper>
    </div>
  ],
  'boleto': [
    <div className='custom-block two-columns'>
      <BlockAvatarWrapper type={BasicType.BUTTON} payload={{
        attributes: {
        href: '%LINK_BOLETO%',
        },
        data: {
        value: {
          content: 'Acessar Boleto'
        }
        }
      }}>
        <div className='container-box full'>
        <img src='images/link-solid.svg' alt='boleto imagem' />
          <span>Link Boleto</span>
        </div>
      </BlockAvatarWrapper>
      <BlockAvatarWrapper type={CustomBlocksType.PRODUCT_CHECKOUT}>
        <div className='container-box'>
        <img src='images/cart-shopping-solid.svg' alt='produtos checkout imagem' />
        <span>Produtos Checkout</span>
        </div>
      </BlockAvatarWrapper>
      <BlockAvatarWrapper type={CustomBlocksType.PRODUCT_RECOMMENDATION}>
        <div className='container-box'>
        <img src='images/box-open-solid.svg' alt='produtos recomendados imagem' />
        <span>Produtos Recomendados</span>
        </div>
      </BlockAvatarWrapper>
      <BlockAvatarWrapper type={CustomBlocksType.MENU_NAV} payload={payloadMenu}>
        <div className='container-box'>
        <img src='https://api.iconify.design/eva/menu-fill.svg' alt='produtos recomendados imagem' />
        <span>Menu</span>
        </div>
      </BlockAvatarWrapper>
    </div>
  ],
  'pix': [
    <div className='custom-block two-columns'>
      <BlockAvatarWrapper type={BasicType.BUTTON} payload={{
        attributes: {
        href: '%LINK_BOLETO%',
        },
        data: {
        value: {
          content: 'Finalizar Pagamento'
        }
        }
      }}>
        <div className='container-box full'>
        <img src='images/link-solid.svg' alt='pix imagem' />
          <span>Link Pix</span>
        </div>
      </BlockAvatarWrapper>
      <BlockAvatarWrapper type={CustomBlocksType.PRODUCT_CHECKOUT}>
        <div className='container-box'>
        <img src='images/cart-shopping-solid.svg' alt='produtos checkout imagem' />
        <span>Produtos Checkout</span>
        </div>
      </BlockAvatarWrapper>
      <BlockAvatarWrapper type={CustomBlocksType.PRODUCT_RECOMMENDATION}>
        <div className='container-box'>
        <img src='images/box-open-solid.svg' alt='produtos recomendados imagem' />
        <span>Produtos Recomendados</span>
        </div>
      </BlockAvatarWrapper>
      <BlockAvatarWrapper type={CustomBlocksType.MENU_NAV} payload={payloadMenu}>
        <div className='container-box'>
        <img src='https://api.iconify.design/eva/menu-fill.svg' alt='produtos recomendados imagem' />
        <span>Menu</span>
        </div>
      </BlockAvatarWrapper>
    </div>
  ],
  'others': [
    <div className='custom-block two-columns'>
      <BlockAvatarWrapper type={CustomBlocksType.PRODUCT_RECOMMENDATION}>
        <div className='container-box'>
        <img src='images/box-open-solid.svg' alt='produtos recomendados imagem' />
        <span>Produtos Recomendados</span>
        </div>
      </BlockAvatarWrapper>
      <BlockAvatarWrapper type={CustomBlocksType.MENU_NAV} payload={payloadMenu}>
        <div className='container-box'>
        <img src='https://api.iconify.design/eva/menu-fill.svg' alt='produtos recomendados imagem' />
        <span>Menu</span>
        </div>
      </BlockAvatarWrapper>
    </div>
  ]
}
import {
	AttributesPanelWrapper,
	ContainerBackgroundColor,
	Color,
	SelectField,
	NumberField,
	EditGridTabField,
	TextField,
} from 'easy-email-extensions';
import { Collapse, Grid, Space } from '@arco-design/web-react';
import { useFocusIdx } from 'easy-email-editor';
import { fontList } from '../categories';
import { pixelAdapter } from '../utils';
import { IMenuBlock } from '.';
import { IconLink } from '@arco-design/web-react/icon';
  
export function Panel() {
	const { focusIdx } = useFocusIdx();
	if (!focusIdx) return null;

	return (
	<AttributesPanelWrapper>
		<Collapse defaultActiveKey={['0', '1', '2']}>
		<Collapse.Item
			name='1'
			header={t('Color')}
		>
			<Grid.Row>
			<Grid.Col span={11}>
				<Color />
			</Grid.Col>
			<Grid.Col
				offset={1}
				span={11}
			>
				<ContainerBackgroundColor title={t('Background color')} />
			</Grid.Col>
			</Grid.Row>
		</Collapse.Item>
		<Collapse.Item
			name='2'
			header={t('Typography')}
		>
			<Space direction='vertical'>
			<Grid.Row>
			<Grid.Row>
				<Grid.Col span={11}>
				<SelectField
					label={t('Font family')}
					name={`${focusIdx}.attributes.font-family`}
					options={fontList}
				/>
				</Grid.Col>
				<Grid.Col
				offset={1}
				span={11}
				>
				<NumberField
					label='Font size (px)'
					name={`${focusIdx}.attributes.font-size`}
					config={pixelAdapter}
					autoComplete='off'
				/>
				</Grid.Col>
				</Grid.Row>
			</Grid.Row>
			</Space>
		</Collapse.Item>
		<Collapse.Item
		name='2'
		header={'Itens'}
		>
			<EditGridTabField
			tabPosition='top'
			name={`${focusIdx}.data.value.links`}
			label=''
			labelHidden
			renderItem={(item, index) => (
				<MenuElement
				item={item}
				index={index}
				/>
			)}
			/>
		</Collapse.Item>
		</Collapse>
	</AttributesPanelWrapper>);
}

function MenuElement({
	index
  }: {
	item: IMenuBlock['data']['value']['links'][0];
	index: number;
  }) {
	const { focusIdx } = useFocusIdx();
  
	return (
	  <Space direction='vertical'>
		<Grid.Row>
		  <Grid.Col span={11}>
			<TextField
			  label={t('Content')}
			  name={`${focusIdx}.data.value.links.[${index}].label`}
			  quickchange
			/>
		  </Grid.Col>
		  <Grid.Col
			offset={1}
			span={11}
		  >
			<TextField
			  prefix={<IconLink />}
			  label={t('Link')}
			  name={`${focusIdx}.data.value.links.[${index}].href`}
			/>
		  </Grid.Col>
		</Grid.Row>
	  </Space>
	);
}
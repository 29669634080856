import { Button } from "antd";
import { ChangeEventHandler, useContext, useEffect, useRef, useState } from "react";
import { uploadImage } from "../../../services/storage";
import { Notification } from '@arco-design/web-react';
import { PrefixContext } from "./UploaderEditor";

export const FileUpload = () => {
	const prefix = useContext(PrefixContext);
	const fileInputRef = useRef<HTMLInputElement>(null);
	const [file, setFile] = useState<File>();

	const openFilePicker = () => {
		if (fileInputRef.current) {
		  fileInputRef.current.click();
		}
	};

	const handleFileChange: ChangeEventHandler<HTMLInputElement> = (e) => {
		const selectedFile = e.target.files;
		if (selectedFile) {
			setFile(selectedFile[0]);
		}
	};

	useEffect(() => {
		if (!file) { return }
		(async () => {
			try {
				await uploadImage(file as Blob, `${prefix}/${(new Date().toISOString())}`);
				Notification.success({
					title: 'Arquivo salvo com sucesso!',
					content: 'Pode usar sua imagem!',
					showIcon: true,
					position: 'bottomLeft',
				});
			} catch {
				Notification.error({
					title: 'Ocorreu um erro!',
					content: 'Não foi possivel salvar sua imagem, tente mais tarde.',
					showIcon: true,
					position: 'bottomLeft',
				});
			}
		})();
	}, [file, prefix]);

	return (
	<>
	  <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
		accept=".png, .jpg, .jpeg, .gif"
      />
	  <Button type='primary' style={{ marginLeft: 12 }} onClick={openFilePicker}>
		{'Carregar'}
	  </Button>
	</>
	);
  };
import { initializeApp } from 'firebase/app';

const config = {
  apiKey: "AIzaSyAcHR_zc5K6A_3xyBJR9fUedAXGpKuGXbI",
  authDomain: "feisty-port-324014.firebaseapp.com",
  projectId: "feisty-port-324014",
  storageBucket: "feisty-port-324014.appspot.com",
  messagingSenderId: "702620495119",
  appId: "1:702620495119:web:3a5092ffb2200636b0227a",
  measurementId: "G-WQ6G01NLT5"
};

export const app = initializeApp(config);

import {
  IBlockData,
  BasicType,
  components,
  createCustomBlock,
  getPreviewClassName,
  AdvancedType,
  mergeBlock,
} from 'easy-email-core';

import { CustomBlocksType } from '../constants';
import { IProductList, IUserConfig } from '../../types/campaign';

const { Column, Wrapper, Text, Button, Image, Group } = components;

export type IProductRecommendation = IBlockData<
  {
    'background-color': string;
    'button-color': string;
    'button-text-color': string;
    'product-name-color': string;
    'product-price-color': string;
    'product-old-price-color': string;
    'title-color': string;
  },
  {
    buttonText: string;
    quantity: number;
  }
>;


const productPlaceholder : IProductList = {
  imagem:
    'https://encrypted-tbn0.gstatic.com/shopping?q=tbn:ANd9GcTrqmNxdLsO0OToYdnME9QuSkKP4OHJZMhcnqlNZrpsIXQDgGdAnDEHoREMkrpnUj8mr3XAbuSiRrlhWGRS5NX5xGrMRBOvMWeA-1kVsUQ&usqp=CAE',
  nome: 'Carro Espacial Novo',
  preco: 'R$ 1.299,00',
  preco_por: 'R$ 2.000,00',
  corBotao: '#000'
};

export const ProductRecommendation = createCustomBlock<IProductRecommendation>({
  name: 'Produtos recomendados',
  type: CustomBlocksType.PRODUCT_RECOMMENDATION,
  validParentType: [BasicType.PAGE, AdvancedType.WRAPPER, BasicType.WRAPPER],
  create: payload => {
    const defaultData: IProductRecommendation = {
      type: CustomBlocksType.PRODUCT_RECOMMENDATION,
      data: {
        value: {
          buttonText: 'EU QUERO',
          quantity: 4,
        },
      },
      attributes: {
        'background-color': '#ffffff',
        'button-text-color': '#ffffff',
        'button-color': '#000',
        'product-name-color': '#414141',
        'product-price-color': '#414141',
        'product-old-price-color': '#b3b3b3',
        'title-color': '#969696',
      },
      children: [
      ],
    };
    return mergeBlock(defaultData, payload);
  },
  render: (props) => {
    const { data, idx, mode, dataSource } = props
    var { buttonText, quantity } = data.data.value;
    const attributes = data.attributes;

    var productList : IProductList[] = new Array(quantity).fill(productPlaceholder);
    if (dataSource?.productList) {
      productList = dataSource.productList;
    }

    if (dataSource?.userConfig) {
      const userConfig : IUserConfig = dataSource.userConfig;
      attributes['button-color'] = userConfig.buttonColor;
      buttonText = userConfig.buttonTxt;
    }
    
    const perWidth = "50%";

    return (
      <Wrapper
        // add class name when testing preview
        css-class={mode === 'testing' ? getPreviewClassName(idx ?? null, data.type) : ''}
        padding='20px 0px 20px 0px'
        border='none'
        direction='ltr'
        text-align='center'
        background-color={attributes['background-color']}
      >
        <Group vertical-align='top' css-class="product_recommendation_group_products">
        {productList.map((item, index) => (
          <Column
            key={index}
            width={perWidth}
            padding='10px'
            border='none'
            vertical-align='top'
          >
            <Image
              align='center'
              height='auto'
              width='100%'
              src={item.imagem}      
            />
            <Text
              font-size='12px'
              padding='0px'
              line-height='1'
              align='center'
              color={attributes['product-name-color']}
              font-family='Montserrat'
            >
              {item.nome}
            </Text>
            {item?.preco_por && item.preco !== item.preco_por &&
              <Text
                font-size='12px'
                padding='2px 0 0 0'
                line-height='1'
                align='center'
                color={attributes['product-old-price-color']}
                text-decoration='line-through'
              >
                {item.preco_por}
              </Text>
            }
            <Text
              font-size='14px'
              padding='8px 0'
              line-height='1'
              align='center'
              color={attributes['product-price-color']}
              font-weight='bold'
            >
              {item.preco}
            </Text>
            <Button
              align='center'
              padding='0px'
              background-color={attributes['button-color']}
              color={attributes['button-text-color']}
              target='_blank'
              vertical-align='middle'
              border='none'
              text-align='center'
              href="#"
              width='100%'
            >
              {buttonText}
            </Button>
          </Column>
        ))}
        </Group>
      </Wrapper>
    );
  },
});

export * from './Panel';
import { Button, Drawer } from '@arco-design/web-react';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { Stack, TextStyle, useBlock, useFocusIdx } from 'easy-email-editor';
import { IFilesFirebase, deleteFile, listFiles } from '../../../services/storage';
import { FileUpload } from './FileUpload';
import { Notification } from '@arco-design/web-react';

export const PrefixContext = createContext<string | null>(null);

export const UploaderEditor: React.FC<{
  visible: boolean;
  setVisible: (v: boolean) => void;
}> = (props) => {

  const prefix = useContext(PrefixContext);
  const { visible, setVisible } = props;

  const { focusIdx } = useFocusIdx();
  const { focusBlock, setValueByIdx } = useBlock();

  const [files, setFiles] = useState<IFilesFirebase[]>([]);

  const onClose = async () => {
    setVisible(false);
  };

  const onSave = (src: string) => {
    if (!focusBlock) return;
    focusBlock.attributes.src = src;
    setValueByIdx(focusIdx, { ...focusBlock });
  };

  const onDelete = async (path: string, src: string) => {
    try {
      await deleteFile(path);
      Notification.success({
        title: 'Arquivo removido com sucesso!',
        content: 'Seu arquivo foi removido dos nossos servidores.',
        showIcon: true,
        position: 'bottomLeft',
      });
    } catch {
      Notification.error({
        title: 'Ocorreu um erro!',
        content: 'Não foi possivel remover a sua imagem, tente mais tarde.',
        showIcon: true,
        position: 'bottomLeft',
      });
    }

    if (src === focusBlock?.attributes.src) { onSave(''); }
  }

  const getImages = useCallback(async () => {
    const filesOnServer = await listFiles(`images/${prefix}`);
    setFiles(filesOnServer);
  }, [prefix]);

  const formatDate = (dateString: string) => {
    const date = dateString.split('T')[0].split('-');
    return `${date[2]}/${date[1]}/${date[0]}`;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { getImages() }, [files]);

  return (
    <Drawer
      placement='left'
      headerStyle={{ display: 'block', lineHeight: '48px' }}
      title={(
        <Stack distribution='equalSpacing'>
          <TextStyle variation='strong' size='large'>
            {t('Gerenciador de Imagens')}
            <FileUpload />
          </TextStyle>
          <Stack>
            <Button type='primary' onClick={onClose}>
              {'Sair'}
            </Button>
          </Stack>
        </Stack>
      )}
      closable={false}
      escToExit={false}
      width='100vw'
      visible={visible}
      footer={null}
      className='upload-editor'
    >
      <div style={{
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
      }}>
        <div
          style={{
            height: 'fit-content',
            display: 'grid',
            gridTemplateColumns: '33% 33% 33%',
            gridGap: 12,
          }}
        >
        
          {files.map((file, idx) => (
            <div
            key={idx}
            style={{
              display: 'flex',
              width: 'fit-content',
              height: 250,
              boxShadow: '#373f470a 0 0 0 1px, #373f4714 0 4px 4px, #373f4714 0 8px 24px',
              padding: 12,
              borderRadius: 12
            }}
            >
              <img alt='' src={file.src} width={250} height={'100%'} style={{ objectFit: 'scale-down', borderRadius: 6, backgroundColor: '#ccc', padding: 4 }}/>
              <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '0 12px',
                justifyContent: 'center'
              }}
              >

                <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyItems: 'center'
                }}>
                  <span color='#f4f4' style={{ fontSize: 10 }}>upload: {formatDate(file.metadata.timeCreated)}</span>
                  <span color='#4D4D4D' style={{ wordBreak: 'break-word'}}>{file.file.name}</span>
                </div>
                <div style={{
                  display: 'flex',
                  marginTop: 'auto',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  padding: 6,
                  borderTop: '1px solid #ccc',
                  
                }}>
                  <div style={{
                    display: 'flex',
                  }}>
                    <Button type='text' size='mini' href={file.src} target='_blank'>
                      <img src='https://api.iconify.design/majesticons/open-line.svg' height={'100%'} width={'100%'} alt='' />
                    </Button>
                    <Button type='text' size='mini' onClick={() => onDelete(file.file.fullPath, file.src)}>
                      <img src='https://api.iconify.design/codicon/trash.svg' height={'100%'} width={'100%'} alt='' />
                    </Button>
                  </div>
                  <Button type='outline' onClick={() => { onSave(file.src); onClose(); }}>
                    {'Inserir'}
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Drawer>
  );
};
import {
  AttributesPanelWrapper,
  Padding,
  TextDecoration,
  FontWeight,
  FontStyle,
  Height,
  ContainerBackgroundColor,
  Color,
  Align,
  LineHeight,
  LetterSpacing,
  SelectField,
  NumberField,
} from 'easy-email-extensions';
import { Collapse, Grid, Space } from '@arco-design/web-react';
import { useFocusIdx } from 'easy-email-editor';
import { fontList } from '../categories';
import { pixelAdapter } from '../utils';

export function Panel() {
  const { focusIdx } = useFocusIdx();
  if (!focusIdx) return null;

  return (
    <AttributesPanelWrapper>
      <Collapse defaultActiveKey={['0', '1', '2']}>
        <Collapse.Item
          name='0'
          header={t('Dimension')}
        >
          <Space direction='vertical'>
            <Height />
            <Padding showResetAll />
          </Space>
        </Collapse.Item>
        <Collapse.Item
          name='1'
          header={t('Color')}
        >
          <Grid.Row>
            <Grid.Col span={11}>
              <Color />
            </Grid.Col>
            <Grid.Col
              offset={1}
              span={11}
            >
              <ContainerBackgroundColor title={t('Background color')} />
            </Grid.Col>
          </Grid.Row>
        </Collapse.Item>
        <Collapse.Item
          name='2'
          header={t('Typography')}
        >
          <Space direction='vertical'>
            <Grid.Row>
            <Grid.Row>
              <Grid.Col span={11}>
                <SelectField
                  label={t('Font family')}
                  name={`${focusIdx}.attributes.font-family`}
                  options={fontList}
                />
              </Grid.Col>
              <Grid.Col
                offset={1}
                span={11}
              >
                <NumberField
                  label='Font size (px)'
                  name={`${focusIdx}.attributes.font-size`}
                  config={pixelAdapter}
                  autoComplete='off'
                />
              </Grid.Col>
              </Grid.Row>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col span={11}>
                <LineHeight />
              </Grid.Col>
              <Grid.Col
                offset={1}
                span={11}
              >
                <LetterSpacing />
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col span={11}>
                <TextDecoration />
              </Grid.Col>
              <Grid.Col
                offset={1}
                span={11}
              >
                <FontWeight />
              </Grid.Col>
            </Grid.Row>

            <Align />

            <FontStyle />

            <Grid.Row>
              <Grid.Col span={11} />
              <Grid.Col
                offset={1}
                span={11}
              />
            </Grid.Row>
          </Space>
        </Collapse.Item>
      </Collapse>
    </AttributesPanelWrapper>
  );
}
import React from 'react';
import {
	Padding,
	Border,
	BackgroundColor,
	Color,
	Width,
	ContainerBackgroundColor,
	Align,
	FontSize,
	FontStyle,
	FontWeight,
	FontFamily,
	TextDecoration,
	LineHeight,
	LetterSpacing,
	TextField,
	AttributesPanelWrapper,
	ClassName
} from 'easy-email-extensions';
import { Collapse, Grid, Space } from "@arco-design/web-react";
import { useFocusIdx } from 'easy-email-editor';

export function Panel() {
  const { focusIdx } = useFocusIdx();
  return (
    <AttributesPanelWrapper>
      <Collapse defaultActiveKey={['-1', '0','1', '2', '3']}>
        <Collapse.Item
          name='-1'
          header={t('Setting')}
        >
          <Space direction='vertical'>
            <TextField
              label={(
                <Space>
                  <span>{t('Content')}</span>
                </Space>
              )}
              name={`${focusIdx}.data.value.content`}
            />
          </Space>
        </Collapse.Item>

        <Collapse.Item
          name='0'
          header={t('Dimension')}
        >
          <Space direction='vertical'>
            <Grid.Row>
              <Grid.Col span={11}>
                <Width />
              </Grid.Col>
              <Grid.Col
                offset={1}
                span={11}
              >
                <FontWeight />
              </Grid.Col>
            </Grid.Row>

            <Padding
              title={t('Padding')}
              attributeName='padding'
              showResetAll
            />
            <Padding
              title={t('Inner padding')}
              attributeName='inner-padding'
            />
          </Space>
        </Collapse.Item>

        <Collapse.Item
          name='1'
          header={t('Color')}
        >
          <Space direction='vertical'>
            <Grid.Row>
              <Grid.Col span={11}>
                <Color title={t('Text color')} />
              </Grid.Col>
              <Grid.Col
                offset={1}
                span={11}
              >
                <BackgroundColor title={t('Button color')} />
              </Grid.Col>
              <Grid.Col span={11}>
                <ContainerBackgroundColor title={t('Background color')} />
              </Grid.Col>
            </Grid.Row>
          </Space>
        </Collapse.Item>

        <Collapse.Item
          name='2'
          header={t('Typography')}
        >
          <Space direction='vertical'>
            <Grid.Row>
              <Grid.Col span={11}>
                <FontFamily />
              </Grid.Col>
              <Grid.Col
                offset={1}
                span={11}
              >
                <FontSize />
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col span={11}>
                <FontWeight />
              </Grid.Col>
              <Grid.Col
                offset={1}
                span={11}
              >
                <LineHeight />
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col span={11}>
                <TextDecoration />
              </Grid.Col>
              <Grid.Col
                offset={1}
                span={11}
              >
                <LetterSpacing />
              </Grid.Col>
            </Grid.Row>
            <Align />

            <FontStyle />
          </Space>
        </Collapse.Item>

        <Collapse.Item
          name='3'
          header={t('Border')}
        >
          <Border />
        </Collapse.Item>
        <Collapse.Item
          name='4'
          header={t('Extra')}
        >
          <Grid.Col span={24}>
            <ClassName />
          </Grid.Col>
        </Collapse.Item>
      </Collapse>
    </AttributesPanelWrapper>
  );
}
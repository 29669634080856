import { ColorPickerField, TextField, AttributesPanelWrapper } from 'easy-email-extensions';
import { useFocusIdx, Stack, } from 'easy-email-editor'
import { Collapse } from 'antd';

export function Panel() {
  const { focusIdx } = useFocusIdx();
  return (
    <AttributesPanelWrapper>
      <Collapse defaultActiveKey={['1', '2', '3']}>
        <Collapse.Panel key='1' header='Wrapper Setting'>
          <Stack vertical>
            <ColorPickerField
              label='Background color'
              name={`${focusIdx}.attributes.background-color`}
              inline
            />
            <ColorPickerField
              label='Title color'
              name={`${focusIdx}.attributes.title-color`}
              inline
            />
          </Stack>
        </Collapse.Panel>
        <Collapse.Panel key='2' header='Setting button'>
          <Stack vertical>
            <TextField
              label='Button text'
              name={`${focusIdx}.data.value.buttonText`}
              inline
            />
            <ColorPickerField
              label='Button color'
              name={`${focusIdx}.attributes.button-color`}
              inline
            />
            <ColorPickerField
              label='Button text color'
              name={`${focusIdx}.attributes.button-text-color`}
              inline
            />
          </Stack>
        </Collapse.Panel>
        <Collapse.Panel key='3' header='Setting Product'>
          <Stack vertical>
            <ColorPickerField
              label='Product name color'
              name={`${focusIdx}.attributes.product-name-color`}
              inline
            />
            <ColorPickerField
              label='Product price color'
              name={`${focusIdx}.attributes.product-price-color`}
              inline
            />
          </Stack>
        </Collapse.Panel>
      </Collapse>

    </AttributesPanelWrapper>
  );
}
import { useEffect, useState } from 'react';
import { NumberField, Padding, SelectField } from 'easy-email-extensions';
import {
  EditGridTabField,
  InputWithUnitField,
  RadioGroupField,
  TextField,
  Align,
  ContainerBackgroundColor,
  FontStyle,
  FontWeight,
  AttributesPanelWrapper,
  Color,
  TextDecoration,
  LineHeight,
} from 'easy-email-extensions';
import { IconLink } from '@arco-design/web-react/icon';
import { Collapse, Grid, Space, Select } from '@arco-design/web-react';
import { useBlock, useFocusIdx } from 'easy-email-editor';
import { ISocial } from 'easy-email-core';
import { fontList } from '../categories';
import { pixelAdapter } from '../utils';
import { FLAT_LIST_SOCIAL_ICONS, LISTSOCIALICONS, SocialListIconsGenerate, socialListToElementObject } from './components/constants';

const options = [
  {
    value: 'vertical',
    get label() {
      return t('vertical');
    },
  },
  {
    value: 'horizontal',
    get label() {
      return t('horizontal');
    },
  },
];

export function Panel() {
  const { focusIdx } = useFocusIdx();
  const { focusBlock } = useBlock();
  const value = focusBlock?.data.value as ISocial['data']['value'];
  if (!value) return null;

  return (
    <AttributesPanelWrapper style={{ padding: 0 }}>
      <Collapse defaultActiveKey={['0', '1', '2', '3']}>
        <Collapse.Item
          name='1'
          header={t('Setting')}
        >
          <Space direction='vertical'>
            <RadioGroupField
              label={t('Mode')}
              name={`${focusIdx}.attributes.mode`}
              options={options}
            />

            <Align />
          </Space>
        </Collapse.Item>

        <Collapse.Item
          name='3'
          header={t('Typography')}
        >
          <Space direction='vertical'>
		        <Grid.Row>
              <Grid.Col span={11}>
                <SelectField
                  label={t('Font family')}
                  name={`${focusIdx}.attributes.font-family`}
                  options={fontList}
                />
              </Grid.Col>
              <Grid.Col
                offset={1}
                span={11}
              >
                <NumberField
                  label='Font size (px)'
                  name={`${focusIdx}.attributes.font-size`}
                  config={pixelAdapter}
                  autoComplete='off'
                />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col span={11}>
                <FontWeight />
              </Grid.Col>
              <Grid.Col
                offset={1}
                span={11}
              >
                <LineHeight />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col span={11}>
                <Color />
              </Grid.Col>
              <Grid.Col
                offset={1}
                span={11}
              >
                <ContainerBackgroundColor title={t('Background color')} />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col span={11}>
                <TextDecoration />
              </Grid.Col>
              <Grid.Col
                offset={1}
                span={11}
              >
                <FontStyle />
              </Grid.Col>
            </Grid.Row>
          </Space>
        </Collapse.Item>

        <Collapse.Item
          name='2'
          header={t('Social item')}
          contentStyle={{ padding: 10 }}
        >
          <Grid.Row>
            <Grid.Col span={24} style={{ marginBottom: 10, width: '100%'}}>
              <span>Ícones</span>
              <SocialSelectOption />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <EditGridTabField
              tabPosition='top'
              name={`${focusIdx}.data.value.elements`}
              label=''
              labelHidden
              renderItem={(item, index) => (
                <SocialElement
                  item={item}
                  index={index}
                />
              )}
            />
          </Grid.Row>
        </Collapse.Item>

        <Collapse.Item
          name='0'
          header={t('Dimension')}
        >
          <Space
            direction='vertical'
            size='large'
          >
            <Grid.Row>
              <Grid.Col span={11}>
                <InputWithUnitField
                  label={t('Icon width')}
                  name={`${focusIdx}.attributes.icon-size`}
                />
              </Grid.Col>
              <Grid.Col
                offset={1}
                span={11}
              >
                <TextField
                  label={t('Border radius')}
                  name={`${focusIdx}.attributes.border-radius`}
                />
              </Grid.Col>
            </Grid.Row>

            <Padding />
            <Padding
              attributeName='inner-padding'
              title={t('Icon padding')}
            />
            <Padding
              attributeName='text-padding'
              title={t('Text padding')}
            />
          </Space>
        </Collapse.Item>
      </Collapse>
    </AttributesPanelWrapper>
  );
}


function SocialSelectOption() {
  const { focusIdx } = useFocusIdx();
  const { focusBlock, setValueByIdx } = useBlock();
  
  const [idx , setIdx] = useState('0');

  const onChange = (v: any) => {
    if (!focusBlock) return;
    const newElements: ISocial['data']['value']['elements'] = socialListToElementObject(LISTSOCIALICONS[v]);
    focusBlock.data.value.elements = newElements;
    setIdx(v);
    setValueByIdx(focusIdx, focusBlock);
  }

  return (
    <Select
      options={SocialListIconsGenerate}
      value={idx}
      onChange={onChange}
      style={{
        display: 'flex',
        flex: 1,
        width: 'fit-content',
        alignItems: 'center'
      }}
      
    />
  ); 
}

function SocialElement({
  index
}: {
  item: ISocial['data']['value']['elements'][0];
  index: number;
}) {
  const { focusBlock, setValueByIdx } = useBlock();
  const { focusIdx } = useFocusIdx();

  const [src, setSrc] = useState((focusBlock as ISocial)?.data.value.elements[index]?.src || '');

  const save = (icon: string) => {
    if (!focusBlock) return;
    focusBlock.data.value.elements[index].src = icon;
    setValueByIdx(focusIdx, { ...focusBlock });
  };

  useEffect(() => {
    setSrc(focusBlock?.data.value.elements[index]?.src || '');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusBlock?.data.value.elements[index]?.src]);

  return (
    <Space direction='vertical'>
      <ImageField
        origin={src}
        onChange={save}
      />

      <Grid.Row>
        <Grid.Col span={11}>
          <TextField
            label={t('Content')}
            name={`${focusIdx}.data.value.elements.[${index}].content`}
            quickchange
          />
        </Grid.Col>
        <Grid.Col
          offset={1}
          span={11}
        >
          <TextField
            prefix={<IconLink />}
            label={t('Link')}
            name={`${focusIdx}.data.value.elements.[${index}].href`}
          />
        </Grid.Col>
      </Grid.Row>
    </Space>
  );
}


function ImageField({
  origin,
  onChange,

}: {
  origin: string,
  onChange: (icon: string) => void
}) {

  const [modalIsOpen, setOpenModal] = useState(false)

  const handleChangeIcon = (icon: string) => {
    onChange(icon);
    setOpenModal(false);
  }

  return (
  <>
    <img alt={origin} src={origin} width={100} height={100} onClick={() => setOpenModal(true)} />
    {modalIsOpen && (
      <div style={{
        backgroundColor: 'white',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        maxHeight: '180px',
        overflow: 'scroll',
        gridGap: 5,
        padding: 12
      }}>
        {
        FLAT_LIST_SOCIAL_ICONS
          .map((icon, idx) => (
          <div key={idx} style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: '#373f470a 0 0 0 1px, #373f4714 0 4px 4px, #373f4714 0 8px 24px'
          }}
          onClick={() => handleChangeIcon(icon)}
          >
            <img src={icon} alt='' width={30} height={30}/>
          </div>
        ))}
        <img
        style={{
          position: 'absolute',
          top: 4,
          right: 4,
          borderRadius: 12,
          backgroundColor: 'white',
          padding: 4,
          flex: 1,
          boxShadow: '#373f470a 0 0 0 1px, #373f4714 0 4px 4px, #373f4714 0 8px 24px'
        }}
        src='https://api.iconify.design/iconamoon/close-thin.svg'
        width={24}
        height={24}
        alt=''
        onClick={() => setOpenModal(false)}
        />
      </div>
    )}
  </>
  );
}
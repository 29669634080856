import Service from '../../services/campaign';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient
} from '@tanstack/react-query';
import { CampaignPostDto } from '../../dtos/campaign-post.dto';
import { Campaign } from '../../types/campaign';

function useCampaignPost(
  options?: Omit<UseMutationOptions<Campaign, unknown, CampaignPostDto, unknown>,'mutationFn'>,
): UseMutationResult<Campaign, unknown, CampaignPostDto, unknown> {

  const queryClient = useQueryClient();
  const { post } = new Service();

  return useMutation(post, {
    ...options,
    onSuccess: (data, vars, ctx) => {
      queryClient.invalidateQueries()
      options?.onSuccess?.(data, vars, ctx)
    },
  });
};

export { useCampaignPost };
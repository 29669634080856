import { v4 } from 'uuid';
import { app } from '../config/firebase';
import { StorageReference, deleteObject, getDownloadURL, getStorage, listAll, ref, uploadBytes, getMetadata, FullMetadata } from 'firebase/storage';
import { FirebaseError } from 'firebase/app';


export type IFilesFirebase = {
	file: StorageReference,
	src: string,
	metadata: FullMetadata
}

export const storage = getStorage(app);

export const uploadImage = async (blob: Blob, fileName?: string) => {
    const name = (blob as File).name.split('.');
    const type = name[name.length-1];
    const url = fileName ? `images/${fileName}.${type}` : `images/${v4()}.${type}`
    const reference = ref(storage, url);
    await uploadBytes(reference, blob);
    const downloadURL = await getDownloadURL(reference);
    return downloadURL;
};

export const listFiles = async (url: string) => {
	try {
		const reference = ref(storage, url);
		const listResult = await listAll(reference);
		const files = listResult.items.map(async(item) => {
			return {
				file: item,
				src: await getDownloadURL(item),
				metadata: await getMetadata(item)
			}
		});
		const filesProcessed =  await Promise.all(files) as IFilesFirebase[];
		return filesProcessed.sort((a,b) => a.metadata.timeCreated > b.metadata.timeCreated ? -1 : 1);
	} catch (error) {
		console.error('Erro ao listar arquivos:', error);
		console.log('Código de erro:', (error as FirebaseError).code);
		console.log('Mensagem de erro:', (error as FirebaseError).message);
		return [];
	}
};

export const deleteFile = async (path: string) => {
	try {
		const reference = ref(storage, path);
		await deleteObject(reference);
		return true;
	} catch (e) {
		console.log('Error: ', e);
		return false;
	}
}

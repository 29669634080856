import { BasicType, IBlockData, createCustomBlock, getPreviewClassName, mergeBlock } from "easy-email-core";
import { CustomBlocksType } from '../constants';
import { components } from "easy-email-core";
export * from './Panel';

const { Wrapper, Group, Button, Column } = components;

type ILink = {
	href: string,
	label: string
}

export type IMenuBlock = IBlockData<
  {
    'container-background-color': string;
    'color': string;
	'font-size': string;
	'font-family': string;
  },
  {
    links: ILink[];
  }
>;

export const MenuBlock = createCustomBlock<IMenuBlock>({
	name: 'Menu',
	type: CustomBlocksType.MENU_NAV,
	validParentType: [BasicType.PAGE],
	create: payload => {
		const defaultData: IMenuBlock = {
		type: CustomBlocksType.MENU_NAV,
		data: {
			value: {
			links: [],
			},
		},
		attributes: {
			'container-background-color': '#ffffff',
			'color': '#000',
			'font-size': '12px',
			'font-family': ''
		},
		children: [],
		};
		return mergeBlock(defaultData, payload);
	},
	render: (props) => {
		const { data, idx, mode } = props
		const attributes = data.attributes;
		const { links } = data.data.value;
		const perWidth = links.length ? 100/links.length : 100;
		return (
		<Wrapper
			// add class name when testing preview
			css-class={mode === 'testing' ? getPreviewClassName(idx ?? null, data.type) : ''}
			padding='20px 0px 20px 0px'
			border='none'
			direction='ltr'
			text-align='center'
			background-color={attributes['container-background-color']}
			full-width="true"
		>
			<Group
			width='100%'
			css-class='menu_navbar'
			>
				{links.map((link, idx) => (
					<Column key={idx} width={`${perWidth}%`}>
						<Button
							align='center'
							background-color={attributes['container-background-color']}
							inner-padding="0px"
							padding="0px"
							color={attributes['color']}
							target='_blank'
							vertical-align='middle'
							border='none'
							text-align='center'
							href={link.href}
							font-family={attributes['font-family']}
							font-size={attributes['font-size']}
						>
							{link.label}
						</Button>
					</Column>
				))}
			</Group>
		</Wrapper>
		);
	},
});

export * from './Panel';
import { AdvancedType, BasicType, BlockManager } from 'easy-email-core';
import { BlockAttributeConfigurationManager } from 'easy-email-extensions';
import { CustomBlocksType } from './constants';
import { Panel as ProductRecommendationPanel, ProductRecommendation } from './ProductRecommendation';
import { Panel as ButtonPaymentPanel } from './ButtonPayment';
import { MenuBlock, Panel as MenuPanel } from './MenuBlock';
import { Panel as SocialPanel } from './SocialBlock';
import { Panel as PagePanel } from './PageBlock';
import { Panel as TextPanel } from './TextBlock';
import { ProductCheckout } from './ProductCheckout';
import { Panel as ImagePanel } from './ImageBlock'


// PRODUCT RECOMMENDATION
BlockManager.registerBlocks({
  [CustomBlocksType.PRODUCT_RECOMMENDATION]: ProductRecommendation,
});
BlockAttributeConfigurationManager.add({
  [CustomBlocksType.PRODUCT_RECOMMENDATION]: ProductRecommendationPanel,
});

// PRODUCT CHECKOUT
BlockManager.registerBlocks({
  [CustomBlocksType.PRODUCT_CHECKOUT]: ProductCheckout,
});
BlockAttributeConfigurationManager.add({
  [CustomBlocksType.PRODUCT_CHECKOUT]: ProductRecommendationPanel,
});

// PRODUCT CHECKOUT
BlockManager.registerBlocks({
  [CustomBlocksType.MENU_NAV]: MenuBlock,
});
BlockAttributeConfigurationManager.add({
  [CustomBlocksType.MENU_NAV]: MenuPanel,
});

// BUTTON PAYMENT (USING THE TYPE BasicType.BUTTON to deal with it)
BlockAttributeConfigurationManager.add({
  [BasicType.BUTTON]: ButtonPaymentPanel,
});

// PAGE BLOCK (Setting this components change few properties)
BlockAttributeConfigurationManager.add({
  [BasicType.PAGE]: PagePanel,
});

// TEXT BLOCK (Setting this components change few properties)
BlockAttributeConfigurationManager.add({
  [AdvancedType.TEXT]: TextPanel,
});

// SOCIAL BLOCK
BlockAttributeConfigurationManager.add({
  [AdvancedType.SOCIAL]: SocialPanel,
});

// SOCIAL BLOCK
BlockAttributeConfigurationManager.add({
  [AdvancedType.IMAGE]: ImagePanel,
});

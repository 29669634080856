// eslint-disable-next-line @typescript-eslint/no-unused-expressions
const address = window.location.origin;

export const LISTSOCIALICONS : string[][] = [
	[
		address + '/images/social-icons/dark/facebook.png',
		address + '/images/social-icons/dark/instagram.png',
		address + '/images/social-icons/dark/linkedin.png',
		address + '/images/social-icons/dark/twitter.png'
	],
	[
		address + '/images/social-icons/normal/facebook.png',
		address + '/images/social-icons/normal/instagram.png',
		address + '/images/social-icons/normal/linkedin.png',
		address + '/images/social-icons/normal/twitter.png'
	]
];

function SocialIconComponent(socialIconImg: string[]) {
	return (
	<div style={{ flex: 1, minWidth: '100%', justifyContent: 'space-between', display: 'flex', height: '100%', margin: 3 }}>
		{socialIconImg.map((img, idx) => (
		<div key={idx} style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', margin: '0 16px' }}>
			<img alt='' id={idx.toString()} src={img} height={24} width={24}/>
		</div>
		))}
	</div>);
}

export const SocialListIconsGenerate = LISTSOCIALICONS.map((arraySocialIcons, idx) => {
	return { value: idx.toString(), label: SocialIconComponent(arraySocialIcons) }
});

export const socialListToElementObject = (socialIconsUrl: string[]) => {
	return socialIconsUrl.map(url => ({
		href: '#',
		target: '_blank',
		src: url,
		content: '',
	}));
};

export const FLAT_LIST_SOCIAL_ICONS = LISTSOCIALICONS.reduce((acc, list) => [...acc, ...list], []);
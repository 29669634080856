import {
    UseMutationOptions,
    UseMutationResult,
    useMutation,
    useQueryClient
} from '@tanstack/react-query';
import Service, { Params } from '../../services/campaign';
import { Campaign } from '../../types/campaign';

function useCampaignGet(
  options?: Omit<UseMutationOptions<Campaign, unknown, Params, unknown>,'mutationFn'>,
): UseMutationResult<Campaign, unknown, Params, unknown> {
  
  const queryClient = useQueryClient()
  const { get } = new Service();

  return useMutation(get, {
    ...options,
    onSuccess: (data, vars, ctx) => {
      queryClient.invalidateQueries()
      options?.onSuccess?.(data, vars, ctx)
    },
  })
}

export { useCampaignGet }
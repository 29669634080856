import api from "../config/api";
import { CampaignPostDto } from "../dtos/campaign-post.dto";
import { Campaign } from "../types/campaign";

export type CampaignTypeT = 
"birthday" | 
"cancelOrder" | 
"inativos" | 
"cashback" | 
"obrigado" | 
"welcome" | 
"checkout" |
"boleto" |
"pix" |
"manualCampaign"

export interface Params {
  prefixo: string
  idCampaign: string,
  campaignType: CampaignTypeT
}

export default class CampaignService {
  async get(params: Params): Promise<Campaign> {
    const { data } = await api.get('getContent.php', { params });
    return data;
  }
  
  async post(dto: CampaignPostDto): Promise<Campaign> {
    const { data } = await api.post('postContent.php', dto);
    return data;
  }
}

import {
	BasicType,
	components,
	createCustomBlock,
	getPreviewClassName,
	AdvancedType,
	mergeBlock
} from 'easy-email-core';
import { CustomBlocksType } from '../constants';
import { IProductList, IUserConfig } from '../../types/campaign';
import { IProductRecommendation } from '../ProductRecommendation';

const { Column, Section, Wrapper, Text, Image, Group  } = components;
const productPlaceholder : IProductList = {
	imagem:
		'https://encrypted-tbn0.gstatic.com/shopping?q=tbn:ANd9GcTrqmNxdLsO0OToYdnME9QuSkKP4OHJZMhcnqlNZrpsIXQDgGdAnDEHoREMkrpnUj8mr3XAbuSiRrlhWGRS5NX5xGrMRBOvMWeA-1kVsUQ&usqp=CAE',
	nome: 'Carro Espacial Novo',
	preco: 'R$ 1.299,00',
	preco_por: 'R$ 2.000,00',
	corBotao: '#000'
};

export const ProductCheckout = createCustomBlock<IProductRecommendation>({
	name: 'Produtos checkout',
	type: CustomBlocksType.PRODUCT_CHECKOUT,
	validParentType: [BasicType.PAGE, AdvancedType.WRAPPER, BasicType.WRAPPER],
	create: payload => {
		const defaultData: IProductRecommendation = {
		type: CustomBlocksType.PRODUCT_CHECKOUT,
		data: {
			value: {
			buttonText: 'EU QUERO',
			quantity: 2,
			},
		},
		attributes: {
			'background-color': '#ffffff',
			'button-text-color': '#ffffff',
			'button-color': '#000',
			'product-name-color': '#414141',
			'product-price-color': '#414141',
			'product-old-price-color': '#b3b3b3',
			'title-color': '#969696',
		},
		children: [],
		};
		return mergeBlock(defaultData, payload);
	},
	render: (props) => {
		const { data, idx, mode, dataSource } = props
		var { quantity } = data.data.value;
		const attributes = data.attributes;

		var productList : IProductList[] = new Array(quantity).fill(productPlaceholder);
		if (dataSource?.productList) {
			productList = [...dataSource.productList];
			productList.splice(quantity);
		}

		if (dataSource?.userConfig) {
			const userConfig : IUserConfig = dataSource.userConfig;
			attributes['button-color'] = userConfig.buttonColor;
		}

		return (
		<Wrapper
			// add class name when testing preview
			css-class={mode === 'testing' ? getPreviewClassName(idx ?? null, data.type) : ''}
			padding='20px 0px 20px 0px'
			border='none'
			direction='ltr'
			text-align='center'
			background-color={attributes['background-color']}
		>
			<Group width='100%' css-class='product_checkout_group_products'>
			{productList.map((item, idx) => (
				<Section key={idx} vertical-align="middle">
					<Column vertical-align='middle'>
					<Image
						align='center'
						height='auto'
						width='100%'
						src={item.imagem}      
					/>
					</Column>
					<Column vertical-align='middle'><Text align='center'>{item.nome}</Text></Column>
					<Column vertical-align='middle'><Text align='center' font-weight='bold'>{item.preco}</Text></Column>
				</Section>
			))}
			</Group>
		</Wrapper>
		);
	},
});
  

import {
  ColorPickerField,
  SwitchField,
  TextField,
  Padding,
  Link,
  Align,
  AttributesPanelWrapper,
  Border,
} from 'easy-email-extensions';

import { Collapse, Grid, Space } from '@arco-design/web-react';
import { Stack, useBlock, useFocusIdx } from 'easy-email-editor';
import { useEffect, useState } from 'react';
import { UploaderEditor } from './components/UploaderEditor';

const fullWidthOnMobileAdapter = {
  format(obj: any) {
    return Boolean(obj);
  },
  parse(val: string) {
    if (!val) return undefined;

    return 'true';
  },
};

export function Panel() {
  const { focusIdx } = useFocusIdx();
  const { focusBlock, setValueByIdx } = useBlock();

  const originalWidth = 600;

  const pixelToPercent = (+focusBlock?.attributes.width?.replace('px', '') || 600)/600 * 100;

  const [imageSrc, setImageSrc] = useState(focusBlock?.attributes.src || 'https://easy-email-m-ryan.vercel.app/images/06ca521d-9728-4de6-a709-1b75a828bfc3-2a9b1224-3d71-43b8-b52f-e7cdcdc9107b.png')

  const changeHeightAndWidth = (v:string) => {
    if (!focusBlock) return
    const percent = parseInt(v) || 0;
    const newPercent = originalWidth * percent / 100
    focusBlock.attributes.width = newPercent + 'px';
    focusBlock.attributes.height = 'auto';
    setValueByIdx(focusIdx, { ...focusBlock });
  }

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setImageSrc(focusBlock?.attributes.src || 'https://easy-email-m-ryan.vercel.app/images/06ca521d-9728-4de6-a709-1b75a828bfc3-2a9b1224-3d71-43b8-b52f-e7cdcdc9107b.png');
  }, [focusBlock?.attributes.src])

  return (
    <AttributesPanelWrapper style={{ padding: 0 }}>
      <Collapse defaultActiveKey={['0', '1', '2', '3', '4']}>
        <Collapse.Item
          name='0'
          header={t('Setting')}
        >
          <Stack
            vertical
            spacing='tight'
          >
            
            <ColorPickerField
              label={t('Background color')}
              name={`${focusIdx}.attributes.container-background-color`}
              inline
            />
            <SwitchField
              label={t('Full width on mobile')}
              name={`${focusIdx}.attributes.fluid-on-mobile`}
              config={fullWidthOnMobileAdapter}
            />
          </Stack>
        </Collapse.Item>

        <Collapse.Item
          name='1'
          header={t('Dimension')}
        >
          <Space direction='vertical'>

            <Grid.Row>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    border: '1px solid #ccc',
                    cursor: 'pointer',
                    borderRadius: 4
                  }}
                  onClick={() => setVisible(true)}
                  >
                    <img src={imageSrc} width={'100%'} height='auto' alt='' />
                    <span style={{
                      padding: 8,
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                      <img src='https://api.iconify.design/majesticons/open.svg' alt='' style={{ marginRight: 6 }} />
                      Gerenciador de Imagens
                    </span>
                  </div>
            </Grid.Row>
            <Grid.Row>
              <div
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
              >
                <span color='#4E5969'>Escala</span>
                <input
                  width='100%'
                  defaultValue={pixelToPercent}
                  type="range"
                  id="rangeScaleImage"
                  name="rangeScaleImage" min="10" max="100"
                  onChange={(v) => changeHeightAndWidth(v.target.value)} />
              </div>
            </Grid.Row>
            <Padding showResetAll />
            <Grid.Row>
              <Grid.Col span={24}>
                <Align />
              </Grid.Col>
            </Grid.Row>
          </Space>
        </Collapse.Item>

        <Collapse.Item
          name='2'
          header={t('Link')}
        >
          <Stack
            vertical
            spacing='tight'
          >
            <Link />
          </Stack>
        </Collapse.Item>

        <Collapse.Item
          name='3'
          header={t('Border')}
        >
          <Border />
        </Collapse.Item>

        <Collapse.Item
          name='4'
          header={t('Extra')}
        >
          <Grid.Row>
            <Grid.Col span={11}>
              <TextField
                label={t('title')}
                name={`${focusIdx}.attributes.title`}
              />
            </Grid.Col>
            <Grid.Col
              offset={1}
              span={11}
            >
              <TextField
                label={t('alt')}
                name={`${focusIdx}.attributes.alt`}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Col span={24}>
            <TextField
              label={t('class name')}
              name={`${focusIdx}.attributes.css-class`}
            />
          </Grid.Col>
        </Collapse.Item>
      </Collapse>
      <UploaderEditor visible={visible} setVisible={() => setVisible(false)} />
    </AttributesPanelWrapper>
  );
}
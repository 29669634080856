import { IEmailTemplate } from "easy-email-editor";
import { useCampaignPost } from "../../hooks/campaign";
import { JsonToMjml } from "easy-email-core";
import { useEffect } from "react";
import mjml from 'mjml-browser';
import { CampaignTypeT } from "../../services/campaign";

export function AutoSave({ values, campaignType, prefix, idCampaign } : { values: IEmailTemplate, campaignType: CampaignTypeT, prefix: string, idCampaign: string }) {
	const {
	  mutate: updateBackground,
	} = useCampaignPost();
  
	const saveCampaign = (values: IEmailTemplate) => {
	  const html = mjml(
		JsonToMjml({
		  data: values.content,
		  mode: "production",
		  context: values.content,
		  keepClassName: true,   
		}),
		{
		  beautify: true,
		  validationLevel: "soft",
		}
	  ).html;
  
	  if (prefix && idCampaign)
		updateBackground({
		  idCampaign,
		  prefixo: prefix,
		  json: values,
		  html,
		  campaignType
		});
	}
  
	useEffect(() => {
	  setTimeout(() => {
		saveCampaign(values);
	  }, 1000*5);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values]);
  
	return (<></>);
  }
import { useEffect, useRef, useState } from 'react';
import { BlockManager, BasicType, JsonToMjml } from 'easy-email-core';
import { EmailEditor, EmailEditorProvider, IEmailTemplate } from 'easy-email-editor';
import { useWindowSize } from 'react-use';
import { useCampaignGet, useCampaignPost } from './hooks/campaign';
import { uploadImage } from './services/storage';
import { FormApi } from 'final-form';
import { Button, PageHeader, Notification, Result } from '@arco-design/web-react';
import { useQuery } from './hooks/useQuery';
import { categoriesCustom, defaultCategories, fontList } from './components/categories';
import { type CampaignTypeT } from './services/campaign';
import { ExtensionProps, StandardLayout } from 'easy-email-extensions';
import { IconLeft } from '@arco-design/web-react/icon';
import localesData from './locales/locales.json';
import mjml from 'mjml-browser';
import 'easy-email-editor/lib/style.css';
import 'easy-email-extensions/lib/style.css';
import '@arco-themes/react-easy-email-theme/css/arco.css';
import './index.css'
import './components/index';
import { PrefixContext } from './components/ImageBlock/components/UploaderEditor';
import { AutoSave } from './components/AutoSave';

const pageBlock = BlockManager.getBlockByType(BasicType.PAGE)?.create({
  data: { value: { 'font-family': 'Roboto' } },
  attributes: {
    'background-color': '#FFFFFF'
  },
  children: [
    {
      type: BasicType.WRAPPER,
      attributes: {
        'background-color': '#FFFFFF'
      }
    }
  ]
});

const initialValues = {
  content: pageBlock,
};

export default function App() {
  const { width } = useWindowSize();
  const {
    mutate: find, 
    data: campaign,
    isError: isNotFound,
  } = useCampaignGet();
  const {
    mutate: update, 
    isLoading: isUpdating,
    isSuccess: isUpdated,
    isError: isNotUpdated
  } = useCampaignPost();
  const [_values, setValues] = useState<{}>(initialValues);
  const [header, setHeader] = useState('');
  const [categories, setCategories] = useState<ExtensionProps['categories']>(defaultCategories);
  const query = useQuery();
  const prefixo = query.get('prefixo');
  const idCampaign = query.get('idCampaign');
  const campaignType = query.get('campaignType') as CampaignTypeT;
  const redirectTo = query.get('callback');
  const smallScene = width < 1400;

  const submitRef = useRef<HTMLButtonElement>(null);
  
  useEffect(() => {
    if (prefixo && idCampaign)
      find({ idCampaign, prefixo, campaignType });

    
    setCategories(categoriesCustom(campaignType));
  
  }, [prefixo, idCampaign, campaignType, find]);

  useEffect(() => {
    if (isUpdated) {
      Notification.success({
        title: 'Sucesso',
        content: 'Campanha atualizada com sucesso!',
        showIcon: true,
        position: 'bottomLeft',
      });
      if (redirectTo) window.location.href = redirectTo;
    }
  }, [isUpdated, redirectTo]);

  useEffect(() => {
    if (isNotUpdated)
      Notification.error({
        title: 'Ocorreu um erro!',
        content: 'Não foi possível atualizar campanha, tente mais tarde.',
        showIcon: true,
        position: 'bottomLeft',
      })
  }, [isNotUpdated]);

  useEffect(() => {
    if (campaign?.content.header) { setHeader(campaign.content.header); }

    if (campaign?.content?.json) {
      setValues(campaign.content.json);
    } else {
      setValues({
        subject: '',
        subTitle: '',
        content: pageBlock,
      });
    }
  }, [campaign]);

  useEffect(() => {
    if (campaignType === 'manualCampaign') {
      const interval = setInterval(() => {
        submitRef.current?.click();
      }, 1000*5);

      return () => clearInterval(interval);
    }
  }, [campaignType]);

  const onSubmit = async (
    values: IEmailTemplate,
    _form: FormApi<IEmailTemplate, Partial<IEmailTemplate>>,
  ) => {
    const html = mjml(
      JsonToMjml({
        data: values.content,
        mode: "production",
        context: values.content,
        keepClassName: true,   
      }),
      {
        beautify: true,
        validationLevel: "soft",
      }
    ).html;

    if (prefixo && idCampaign)
      update({
        idCampaign,
        prefixo,
        json: values,
        html,
        campaignType
      });
  };

  return (
    <PrefixContext.Provider value={prefixo?.toString() || null}>
    {!isNotFound && prefixo && idCampaign && _values &&
      <EmailEditorProvider
      data={_values}
      height={'calc(100vh - 72px)'}
      autoComplete
      dashed={false}
      fontList={fontList}
      onSubmit={onSubmit}
      onUploadImage={uploadImage}
      locale={localesData['pt-BR']}
      mergeTags={{
        productList: campaign?.productList || [],
        userConfig: campaign?.userConfig || {}
      }}
    >
      {({ values }, { submit }) => {
        return (
          <>
            <PageHeader
              title={(
                <img
                alt='logo'
                src='https://app.emanda.com.br/assets/newlogo.jpg'
                width={150}
                style={{ objectFit: 'cover' }}
                />
              )}
              backIcon={<IconLeft fontSize={30} />}
              onBack={() => {
                if (redirectTo) window.location.href = redirectTo;
              }}
              subTitle={header}
              extra={
                <Button
                  loading={isUpdating}
                  type='primary' 
                  onClick={() => submit()}>
                    Salvar
                </Button>}>
            </PageHeader>
            <StandardLayout
              categories={categories}
              compact={!smallScene}
              showSourceCode={smallScene}>
              <EmailEditor />
            </StandardLayout>
            
            {campaignType === 'manualCampaign' && values !== _values && <AutoSave
            campaignType={campaignType}
            values={values}
            prefix={prefixo}
            idCampaign={idCampaign}
            />}
          </>
        );
      }}
    </EmailEditorProvider>
    }
    {(!prefixo || !idCampaign) && 
      <div>
        <Result
          status='404'
          title="Não conseguimos encontrar os seguintes parâmetros:"
          subTitle={(!prefixo && !idCampaign) ? "Prefixo e ID da Campanha" : !prefixo ? "prefixo": "ID da Campanha"}></Result>
      </div>
    }
    {isNotFound &&
      <div>
        <Result
          status='403'
          subTitle='Não foi possível localizar campanha.'
          extra={<Button 
            type='primary' 
            onClick={() => find({
              idCampaign: idCampaign!, 
              prefixo: idCampaign!,
              campaignType: campaignType!
            })}>Tentar novamente</Button>}
        ></Result>
      </div>
    }
    </PrefixContext.Provider>
  );
}
